.project {
  background-color: #fff;
  border-radius: 15px;
  .projectImage {
    border-radius: 15px 15px 0 0;
    position: relative;
    aspect-ratio: 1.25;
    overflow: hidden;
    img {
      transition: transform 0.5s ease-in-out;
    }
  }
  .projectTexts {
    padding: 20px 20px 25px;
    .projectRegion {
      color: var(--primary);
      font-weight: 700;
      font-size: 15px;
      margin: 0 0 6px;
    }
    .projectTitle {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.25;
      margin: 0;
    }
    .projectDescription {
      color: #686767;
      font-size: 15px;
      font-weight: 400;
    }
    .projectActions {
      margin: 20px 0 0;
      span {
        border: 1px solid var(--secondary);
        display: inline-block;
        border-radius: 15px;
        color: #000;
        line-height: 1.33;
        padding: 10px 18px;
        transition: background-color 0.3s ease-in-out;
        &:hover {
          background-color: var(--secondary);
        }
      }
    }
  }
  &:hover .projectImage img {
    transform: scale(1.03);
  }
}
