@import '../../styles/mixins';

.articlesList {
  display: grid;
  grid-column-gap: 64px;
  grid-row-gap: 30px;
  margin: 50px 0 0;

  @include tablet {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
  }
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include desktop-lg {
    margin-top: 80px;
  }
}
