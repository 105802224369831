$primary: #376f44;

$tablet-min-width: 768px;
$desktop-min-width: 992px;
$desktop-lg-min-width: 1200px;
$desktop-xl-min-width: 1440px;
$desktop-hd-min-width: 1920px;

@mixin mobile {
  @media (min-width: 425px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-min-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: $desktop-lg-min-width) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: $desktop-xl-min-width) {
    @content;
  }
}

@mixin desktop-hd {
  @media (min-width: $desktop-hd-min-width) {
    @content;
  }
}

@mixin button {
  background: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: 15px;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5;
  padding: 15px 20px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--secondary-dark);
  }
}
