@import '../../styles/mixins';

.projectsList {
  display: grid;
  grid-column-gap: 36px;
  grid-row-gap: 40px;
  margin: 0;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
