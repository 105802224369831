@import '../../styles/mixins';

.offerMap {
  padding: 50px 0;
  @include desktop-lg {
    padding: 75px 0;
  }

  .offerMapLayout {
    display: grid;
    @include desktop {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      grid-column-gap: 30px;
    }
    @include desktop-lg {
      grid-template-columns: auto 710px;
      grid-column-gap: 50px;
    }
    .offerMapTexts {
      h4 {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        line-height: 2;
        margin: 0 0 36px;
      }
      p {
        color: #686767;
        font-weight: 400;
        line-height: 2;
        margin: 0 0 20px;
      }
    }
    .offerMapImage {
      position: relative;
      aspect-ratio: 1.075988;

      .offerMapMarker {
        background-image: url(/images/marker-available.svg);
        display: block;
        position: absolute;
        height: 28px;
        width: 25px;
        transform: translate(-50%, -100%);
      }
    }
  }

  .offerMapHeading {
    @include tablet {
      text-align: left;
    }
  }
}
