@import '../../styles/mixins';

.projects {
  background-color: #f6f6f6;
  padding: 50px 0;
  @include desktop-lg {
    padding: 75px 0;
  }
  .heading {
    margin-bottom: 50px;
    @include desktop-lg {
      margin-bottom: 50px;
    }
  }
}
