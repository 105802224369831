@import '../../styles/mixins';

.banner {
  height: 150vw;
  position: relative;
  max-height: 600px;
  @include tablet {
    height: 60vw;
    max-height: unset;
  }
  @include desktop {
    height: 40vw;
  }
  & > img {
    object-fit: cover;
  }
  .bannerTexts {
    height: 150vw;
    position: relative;
    text-align: center;
    z-index: 1;
    @include tablet {
      height: calc(60vw - 190px);
    }
    @include desktop {
      height: calc(40vw - 110px);
    }
    h1 {
      background-color: var(--primary);
      color: #fff;
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.2;
      margin: 50px 0 0;
      padding: 10px 30px;
      @include tablet {
        font-size: 36px;
      }
      @include desktop {
        font-size: 45px;
        margin-top: 70px;
        padding: 20px 50px 12px;
      }
      @include desktop-lg {
        font-size: 55px;
        margin-top: 100px;
      }
      @include desktop-hd {
        font-size: 55px;
        margin-top: 170px;
      }
    }
    h2 {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin: 20px 0;
      @include desktop {
        font-size: 20px;
      }
    }
  }
  .bannerIcons {
    color: #fff;
    display: none;
    max-width: 1200px;
    margin: 0 auto;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    // padding: 30px 0;
    position: relative;
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 190px;
      padding: 25px 0;
    }
    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      height: 110px;
      align-items: center;
      padding: 0;
    }
    .bannerIcon {
      display: flex;
      align-items: center;
      min-height: 60px;

      &::before {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: '';
        flex-shrink: 0;
        display: inline-block;
        height: 42px;
        margin-right: 5px;
        width: 56px;
      }
      &.bannerIconSafety::before {
        background-image: url(/images/banner-icon-safety.svg);
      }
      &.bannerIconInstallments::before {
        background-image: url(/images/banner-icon-discount.svg);
      }
      &.bannerIconOffer::before {
        background-image: url(/images/banner-icon-offer.svg);
      }
      &.bannerIconLocations::before {
        background-image: url(/images/banner-icon-map.svg);
      }
    }
  }
}
