@import '../../styles/mixins';

.profits {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 31.25vw;
  text-align: center;
  padding: 50px 0;
  position: relative;
  & > img {
    object-fit: cover;
    object-position: center;
  }
  .profitsTexts {
    position: relative;
    width: 100%;
  }

  h3 {
    color: #fff;
    font-size: 36px;
    margin: 0 0 30px;
    @include desktop {
      font-size: 48px;
    }
  }
  .profitsList {
    display: grid;
    grid-gap: 30px;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    max-width: 400px;

    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
      max-width: 600px;
    }
    @include desktop {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: unset;
    }
    li {
      background-color: var(--primary);
      border-radius: 10px;
      color: #fff;
      padding: 50px 30px;
      transition: background-color 0.3s ease-in-out, transform 0.6s ease-in-out;
      span {
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        height: 110px;
        margin-bottom: 30px;
        width: 110px;
      }
      h4 {
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 10px;
      }
      p {
        font-size: 14px;
        line-height: 2;
        max-width: 180px;
        margin: 0 auto;
      }
      &.profitLocations span {
        background-image: url(/images/profit-icon-locations.svg);
      }
      &.profitMedia span {
        background-image: url(/images/profit-icon-media.svg);
      }
      &.profitInstallments span {
        background-image: url(/images/profit-icon-installments.svg);
      }
      &.profitSafety span {
        background-image: url(/images/profit-icon-safety.svg);
      }
      &:hover {
        background-color: var(--primary-dark);
        transform: translateY(-10px);
      }
    }
  }
}
