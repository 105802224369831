.article {
  .articleImage {
    border-radius: 15px;
    position: relative;
    aspect-ratio: 1.490775;
    overflow: hidden;
    img {
      transition: transform 0.5s ease-in-out;
    }
  }
  .articleTexts {
    padding: 20px 15px;
    .articleDate {
      color: var(--primary);
      font-weight: 500;
      font-size: 14px;
      margin: 0 0 10px;
    }
    .articleTitle {
      color: #000;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
      margin: 0;
    }
  }
  &:hover .articleImage img {
    transform: scale(1.03);
  }
}
