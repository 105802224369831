@import '../../styles/mixins';

.about {
  padding: 50px 0;
  @include tablet {
    padding: 75px 0;
  }
  .aboutLayout {
    display: grid;
    grid-row-gap: 50px;
    @include desktop {
      grid-column-gap: 50px;
      grid-template-columns: 350px auto;
    }
    @include desktop-lg {
      grid-gap: 120px;
      grid-template-columns: 473px auto;
    }
    .aboutImage {
      aspect-ratio: 0.707025;
      position: relative;
      max-width: 473px;
      margin: 0 auto;
      width: 100%;
    }
  }
  .aboutHeading {
    margin-bottom: 20px;
    @include tablet {
      text-align: left;
    }
  }
  .aboutTexts {
    h4 {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      line-height: 2;
      margin: 0 0 36px;
    }
    p {
      color: #686767;
      font-weight: 400;
      line-height: 2;
      margin: 0 0 20px;
    }
  }
}
