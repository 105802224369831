@import '../../styles/mixins';

.sliderSlide {
  display: flex;
  flex-direction: column-reverse;
  @include desktop {
    flex-direction: row;
    height: 45vw;
  }
  @include desktop-lg {
    flex-direction: row;
    height: 600px;
  }
  .sliderSlideTexts {
    background-color: var(--primary);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    padding: 20px 30px;
    @include tablet {
      padding: 30px 50px;
    }
    @include desktop {
      padding: 80px 50px;
      width: 400px;
    }
    @include desktop-lg {
      width: 500px;
    }
    .sliderSlideDetails {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 50px;
    }
    strong {
      font-size: 18px;
      line-height: 2;
      margin: 0;
    }
    h2 {
      font-size: 40px;
      line-height: 1.25;
      font-weight: bold;
      margin: 0;
      @include desktop-lg {
        font-size: 48px;
      }
    }
    p {
      margin: 37px 0 0;
      font-size: 18px;
    }
  }
  .sliderSlideImage {
    flex-grow: 1;
    position: relative;
    aspect-ratio: 16 / 9;
    @include tablet {
      aspect-ratio: 2;
    }
    & > img {
      object-fit: cover;
      object-position: center;
    }
    .sliderSlideNav {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 120px;
      @include tablet {
        width: 200px;
      }
      @include desktop {
        flex-direction: column;
        left: 0;
        right: unset;
        width: 100px;
      }
      button {
        background-color: #193034;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        @include tablet {
          height: 80px;
          width: 80px;
        }
        @include desktop-lg {
          height: 100px;
          width: 100px;
        }
        &::before {
          background: url(/images/angle-right.svg) center no-repeat;
          content: '';
          height: 100%;
          width: 100%;
        }
        &.sliderSlideNext::before {
          transform: scaleX(-1);
        }
      }
    }
  }
}
