@import '../../styles/mixins';

.heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  h2 {
    color: #000;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    margin: 0;
    order: 2;
    @include tablet {
      font-size: 32px;
    }
    @include desktop {
      font-size: 40px;
    }
    @include desktop-lg {
      font-size: 48px;
    }
  }
  h3 {
    color: var(--primary);
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    order: 1;
    @include tablet {
      font-size: 16px;
    }
  }
}
